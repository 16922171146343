import { Link } from "react-router-dom";
import ScrollReveal from '../constants/ScrollReveal';

const CategoryCard = ({ category }) => {
 
  return (
    <>
      <Link to={`/listing?category_id=${category.category_id}`} className="flex flex-col gap-2">
        <div className="aspect-[1/1] group relative cursor-pointer bg-[#F9F9F9] rounded-xl product-image overflow-hidden">
          <ScrollReveal animationClass="animation-semiBlur w-full h-full relative z-[4]">
            <img src={category?.image}
              alt={category?.name} className="w-full h-full object-cover" />
          </ScrollReveal>
        </div>
        <ScrollReveal animationClass="animation-zoom-text" >
          <p className="text-center text-sm lg:text-base text-theme-headingColor font-customSemiBold">{category?.name}</p>
        </ScrollReveal>
       
      </Link>
    </>
  );
};

export default CategoryCard;
