const useNameInitials = () => {
  const convertToInitials = (firstName = '', lastName = '') => {
    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    
    const firstNameLetter = trimmedFirstName.charAt(0).toUpperCase() || '';
    const lastNameLetter = trimmedLastName.charAt(0).toUpperCase() || '';

    return firstNameLetter + lastNameLetter;
  };

  return { convertToInitials };
};

export default useNameInitials;



