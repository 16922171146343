import React, { useState, useEffect } from 'react';
import { addToWishList, removeFromWishList } from "../redux/slices/wishListHelperSlice";
import { useDispatch } from 'react-redux';
import useCustomToast from '../hooks/useCustomToast';
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal';

function WishListButton({ item, userData, type, onWishlistPage }) {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();
    const [isWishListLoading, setIsWishListLoading] = useState(false);
    const [isWishListToggled, setIsWishListToggled] = useState(false);

    useEffect(() => {
        if (item) {
            setIsWishListToggled(item.is_in_wishlist === 1 ? true : false);
        }
    }, [item]);

    function doSetupPopupType(status, type = null) {
        dispatch(setPopupType(type));
        dispatch(setPopupStatus(status));
    }

    const addToWishListProduct = async (productId) => {
        const userId = userData?.id;
        const response = await addToWishList({ productId, userId, showToast, setIsWishListLoading });
        if (response === false) {
            setIsWishListToggled(!isWishListToggled);
        }
    }
    const removeToWishListProduct = async (productId) => {
        const userId = userData?.id;
        const response = await removeFromWishList({ productId, userId, showToast, setIsWishListLoading });
        if (onWishlistPage && typeof onWishlistPage === 'function') {
            onWishlistPage();
        }
        if (response === false) {
            setIsWishListToggled(!isWishListToggled);
        }
    }

    const handleWishListButton = (event) => {
        event.preventDefault();
        if (userData?.id) {
            if (!isWishListLoading) {
                setIsWishListToggled(!isWishListToggled);
                isWishListToggled ? removeToWishListProduct(item?.product_id) : addToWishListProduct(item?.product_id);
            } else {
                showToast('error', 'Please wait...');
            }
        } else {
            doSetupPopupType(true, 'login');
        }
    };



    return (
        <>
            {type === 'productCard' &&
                (
                    <div onClick={handleWishListButton}
                        disabled={isWishListLoading}
                        className="absolute overflow-hidden z-[5] shadow-[0px_1px_10px_rgba(0,0,0,0.25)] top-3 end-3 bg-white rounded-full flex justify-center items-center 
                    cursor-pointer size-8">
                        {/* first userData?.id  check then isWishListToggled  */}
                        <img src={userData?.id ?
                            isWishListToggled ? "/assets/icons/heart-icon-fill.svg" : "/assets/icons/heart-icon.svg" : "/assets/icons/heart-icon.svg"}
                            alt="" className={`size-5  mt-px ms-px ${isWishListToggled ? 'animation-heart' : ''}`} />
                    </div>
                )

            }
            {type === 'productDetails' &&
                (
                    <button
                        onClick={handleWishListButton}
                        disabled={isWishListLoading}
                        className={`${!isWishListToggled ? 'border-[#012853] text-[#012853] bg-white' : 'border-[#dc3545] bg-[#ffe9eb] text-[#dc3545]'} theme-button duration-500 ease-in-out text-sm lg:text-base  py-3  `}>
                        {userData?.id ? isWishListToggled ? 'Remove From Wishlist' : 'Add To Wishlist' : 'Add To Wishlist'}
                        {isWishListToggled}
                    </button>
                )
            }
        </>

    )
}

export default WishListButton