import apiService from '../../utility-functions/Api-Services';

const addToWishList = async (data) => {
    try {
        data.setIsWishListLoading(true);
        const getParams = {};
        const postParams = {
            user_id: data?.userId,
            product_id: data?.productId,
        };
        const response = await apiService.postRequest('/move-to-wishlist', getParams, postParams);
        if (response.status === 200) {
            data?.showToast('success', response.message);
            data?.setIsWishListLoading(false);
            return  true;

        } else {
            data?.showToast('error', response.message);
            data?.setIsWishListLoading(false);
            return false;
        }
    } catch (err) {
        data?.showToast('error', err.message || 'An error occurred while fetching Home data.');
        data?.setIsWishListLoading(false);
        return false;
    }
};



const removeFromWishList = async (data, showToast) => {
    try {
        data.setIsWishListLoading(true);
        const getParams = {};
        const postParams = {
            user_id: data.userId,
            product_id: data?.productId,
        };
        const response = await apiService.postRequest('/remove-from-wishlist', getParams, postParams);
        if (response.status === 200) {
            data?.showToast('error', response.message);
            data?.setIsWishListLoading(false);
            return true;

        } else {
            data?.showToast('error', response.message);
            data?.setIsWishListLoading(false);
            return false;
        }
    } catch (err) {
        data?.showToast('error', err.message || 'An error occurred while fetching Home data.');
        data?.setIsWishListLoading(false);
        return false; 
    }
}

export { addToWishList, removeFromWishList };