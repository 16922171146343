// useCmsData.js
import { useState, useEffect } from 'react';
import apiService from '../../utility-functions/Api-Services';
import { getLang } from "../../utility-functions/browser-storage";

const useCmsData = (pageId) => {
  const [cmsData, setCmsData] = useState({ cms_title: '', cms_description: '' });
  const [lang] = useState(() => getLang() || 'en');

  useEffect(() => {
    if (pageId) {
      fetchCmsData(pageId);
    }
  }, [pageId]);

  const fetchCmsData = (id) => {
    const getParams = {
      lang,
      page_id: id,
    };

    apiService.getRequest('/get-cms-page', getParams)
      .then((response) => {
        if (response.status === 200) {
          setCmsData({
            cms_title: response.data.title,
            cms_description: response.data.content,
          });
        } else {
          console.error('Failed to fetch CMS data');
        }
      })
      .catch((err) => {
        console.error(err.message || 'An error occurred while fetching CMS data.');
      });
  };

  return cmsData;
};

export default useCmsData;
