import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: JSON.parse(localStorage.getItem("cartData")) || {},
};

const cartDataSlice = createSlice({
  name: "cartData",
  initialState,
  reducers: {
    setCartData: (state, action) => {
      state.data = action.payload;
      localStorage.setItem("cartData", JSON.stringify(action.payload));
    },

    removeCartData: (state) => {
      state.data = {};
      localStorage.removeItem('cartData');
    },
  },
});

export const { setCartData, removeCartData } = cartDataSlice.actions;
export default cartDataSlice.reducer;
