import apiService from "./Api-Services";

export const addToCartMain = (productDetailData, userData, showToast) => {
  if (userData.id) {
    return addToOnlineCart(productDetailData, userData, showToast);
  } else {
    return addToOfflineCart(productDetailData, showToast);
  }
};

const addToOfflineCart = (productDetailData, showToast) => {
  const cartData = {
    id: productDetailData?.product_id,
    order_item_id: "",
    order_id: "",
    name: productDetailData?.name || "",
    short_description: productDetailData?.short_description || "",
    description: productDetailData?.description || "",
    SKU: "",
    parent_id: "",
    regular_price: productDetailData?.regular_price || "",
    final_price: productDetailData?.final_price || "",
    final_price_kwd: "",
    cost_price: productDetailData?.cost_price || "",
    product_payout: productDetailData?.product_payout || "",
    remaining_quantity: productDetailData?.remaining_quantity || 0,
    quantity: productDetailData?.quantity || 1,
    message: null,
    image: productDetailData?.images[0] || "",
    referrals: productDetailData?.referrals || ""
  };

  let cartDataItems = {
    items: [],
  };
  cartDataItems.items.push(cartData);

  showToast("success", "Product added successfully");

  return Promise.resolve(cartDataItems);
};
const addToOnlineCart = (productDetailData, userData, showToast) => {
  const url = "/add-to-cart";
  const getParams = {};
  const refCode = userData.referral_code != productDetailData.referrals ? productDetailData.referrals : '';
  const postParams = {
    products: String(productDetailData.product_id),
    quantity: "1",
    user_id: userData.id,
    referrals: [refCode],
  };

  return apiService.postRequest(url, getParams, postParams).then((res) => {
    if (res.status === 200) {
      res.data.items = res.data.items.map((item) => {
        if (String(item.id) === String(productDetailData.product_id)) {
          return { ...item, referrals: productDetailData.referrals }; 
        }
        return item; 
      });

      showToast("success", res.message);
      return res.data;
      // return getCartItems(userData);
    } else {
      console.error(res.message);
      return res;
    }
  });
};

export const removeFromCart = (productDetailData, userData, showToast) => {
  if (userData.id) {
    return removeFormOnlineCart(productDetailData, userData, showToast);
  } else {
    return removeFromOfflineCart(productDetailData, showToast);
  }
};

const removeFromOfflineCart = (productDetailData, showToast) => {
  showToast("error", "Product has been removed from cart");
  return Promise.resolve(productDetailData);
};

const removeFormOnlineCart = (productDetailData, userData, showToast) => {
  const url = "/delete-from-cart";
  const getParams = {};

  const postParams = {
    products: String(productDetailData.id),
    quantity: "1",
    order_id: productDetailData.order_id,
    user_id: userData.id,
  };

  return apiService.postRequest(url, getParams, postParams).then((res) => {
    if (res.status === 200) {
      showToast("error", res.message);
      return res.data;
    } else {
      console.error(res.message);
    }
  });
};

export const updateFromCart = (productDetailData, userData, showToast) => {
  if (userData.id) {
    return updateFormOnlineCart(productDetailData, userData, showToast);
  } else {
    return updateFromOfflineCart(productDetailData, showToast)
  }
};

const updateFromOfflineCart = (productDetailData, showToast) => {
  showToast("success", "Order successfully updated.");
  return Promise.resolve(productDetailData) 
};

const updateFormOnlineCart = (productDetailData, userData,showToast) => {
  const url = "/update-cart";
  const getParams = {};

  const postParams = {
    products: String(productDetailData.id),
    quantity: String(productDetailData.quantity),
    order_id: productDetailData.order_id,
    user_id: userData.id,
  };

  return apiService.postRequest(url, getParams, postParams).then((res) => {
    if (res.status === 200) {      
      showToast('success',res.message);
      return res.data;
      // return getCartItems(userData);
    } else {
      console.error(res.message);
      return res;
    }
  });
};

export const checkItemStock = (productDetailData, userData) => {

  let productIds = productDetailData?.map((item) => item?.id);
  let quantities = productDetailData?.map((item) => item?.quantity);
  let orderIds = [...new Set(productDetailData?.map((item) => item?.order_id))];

  const url = "/check-item-stock";
  const getParams = {};
  const postParams = {
    order_id: orderIds?.join(","),
    shipping_address_id: "",
    products: productIds?.join(","),
    quantity: quantities?.join(","),
    user_id: userData?.id,
  };

  return apiService.postRequest(url, getParams, postParams).then((res) => {
    if (res.status === 200) {
      // toast.success(res.message);
      return res.data;
      // return getCartItems(userData);
    } else {
      console.error(res.message);
      return res;
    }
  });
};

export const getCartItems = (userData, showToast) => {
  const url = "/cart-items";
  const getParams = { user_id: userData.id };

  return apiService.getRequest(url, getParams).then((res) => {
    if (res.status === 200) {
      let cartData = res.data;
      if (cartData?.items && cartData?.items?.length > 0) {
        return res.data;
      } else  {
        return res.data ?? []
      }
    } else {
      console.error(res.message);
      return res;
    }
  });
};


export const handleOfflineToOnline = (cartData, userData, showToast) => {
  if (cartData && cartData?.length > 0) {
    const url = "/add-to-cart";
    const getParams = {};
    let products = cartData.map((e) => e.id).join(",");
    let quantity = cartData.map((e) => e.quantity).join(",");
    let referrals = cartData.map((e) => ( e.referrals!='' && (userData.referral_code != e.referrals)) ? e.referrals : '' )
    
    const postParams = {
      products: products,
      quantity: quantity,
      user_id: userData.id,
      referrals: referrals
    };

    return apiService.postRequest(url, getParams, postParams).then((res) => {
      if (res.status === 200) {
        showToast("success", res.message); //
        return res.data;
        // return getCartItems(userData);
      } else {
        console.error(res.message);
        return res;
      }
    });
  }
};
