import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quantity: parseInt(localStorage.getItem('cartQuantity'), 10) || 0,
};

const cartQuantity = createSlice({
  name: "cartQuantity",
  initialState,
  reducers: {
    setCurrentCartCount: (state, action) => {
      state.quantity = action.payload;
      localStorage.setItem('cartQuantity', action.payload);
    },
  },
});

export const { setCurrentCartCount } = cartQuantity.actions;
export default cartQuantity.reducer;