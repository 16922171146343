import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: JSON.parse(localStorage.getItem('userData')) || {},
  walletData: JSON.parse(sessionStorage.getItem('walletData')) || {},
  isLoggedIn: JSON.parse(localStorage.getItem('userData')) !== null,
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
      localStorage.setItem('userData', JSON.stringify(action.payload));
      state.isLoggedIn = true;
    },
    clearUserData: (state) => {
      state.data = {};
      localStorage.removeItem('userData');
      state.isLoggedIn = false;
    },
    setWalletData: (state, action) => {
      state.walletData = action.payload;
      sessionStorage.setItem('walletData', JSON.stringify(action.payload));
    },
    clearWalletData: (state) => {
      state.walletData = {};
      sessionStorage.removeItem('walletData');
    },
    
  },
});

export const { setUserData, clearUserData, setWalletData, clearWalletData } = userDataSlice.actions;
export default userDataSlice.reducer;