export function formatPrice(value) {
    if (value === null || value === undefined) return;
    const num = parseFloat(value);
    if (isNaN(num)) return; 

    const formatter = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: num % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(num);
}
