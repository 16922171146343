import apiService from '../../utility-functions/Api-Services';

const fetchStateData = async () => {
  try {
    const response = await apiService.getRequest('/get-state-list');
    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      console.log(response.message);
    }
  } catch (err) {
    console.error(err.message || 'An error occurred while fetching Home data.');
  }
};

const fetchAreaData = async (id) => {
  try {
    const getParams = { state_id: id };
    const response = await apiService.getRequest('/get-area-list', getParams);
    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      console.log(response.message);
    }
  } catch (err) {
    console.error(err.message || 'An error occurred while fetching Home data.');
  }
};

const fetchDistrictData = async (id) => {
  try {
    const getParams = { state_id: id };
    const response = await apiService.getRequest('/get-district-list', getParams);
    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      console.log(response.message);
    }
  } catch (err) {
    console.error(err.message || 'An error occurred while fetching Home data.');
  }
};

const fetchTalukaData = async (id) => {
  try {
    const getParams = { district_id: id };
    const response = await apiService.getRequest('/get-taluka-list', getParams);
    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      console.log(response.message);
    }
  } catch (err) {
    console.error(err.message || 'An error occurred while fetching Home data.');
  }
};

export { fetchStateData, fetchDistrictData, fetchAreaData, fetchTalukaData };