import React from "react";
import { Link, useLocation } from 'react-router-dom';
import ScrollReveal from '../constants/ScrollReveal';

import WishListButton from './WishListButton';
import { useUserData } from '../hooks/useUserData';
import { formatPrice } from "../utility-functions/formatters";




const ProductCard = ({ item, wishListApiCall }) => {
  const location = useLocation();
  const userData = useUserData(); 
  // const [productHide, setProductHide] = useState(false)
  const handleWishlistPage = () => {
    // setProductHide(true)
    wishListApiCall();
  };
  return (

    <Link to={`/product-details/${item?.product_id}`} className="relative group flex flex-col border border-theme-borderColor shadow-[0px_1px_3px_rgba(0,0,0,0.10)] rounded-lg items-start">
      {item?.product_payout && item?.product_payout > 0 && (
        <div className="absolute z-[5] top-0 start-0 min-w-[100px] flex justify-center items-center bg-orange-500 font-customSemiBold shadow-[0px_1px_10px_rgba(0,0,0,0.25)] text-[10px] lg:text-xs text-white rounded-tl-lg  rounded-br-lg p-0.5 px-2 md:p-2">
          ₹ {formatPrice(item?.product_payout)}
        </div>
      )
      }
      
      <WishListButton item={item} userData={userData} type="productCard"
        onWishlistPage={location.pathname === '/my-account/wishlist' ? handleWishlistPage : null} />


      <ScrollReveal animationClass="animation-semiBlur w-full relative z-[4]">
        <div className="w-full aspect-[1/1] bg-theme-lightGray rounded-t-lg overflow-hidden">
          
          {item?.images && item?.images?.length > 0 && (
            <div className="relative">
              <img
                src={item?.images[0]}
                alt={item?.name}
                className={`aspect-[1/1] w-full mx-auto object-cover rounded-t-lg bg-[#F9F9F9] 
                transition-all duration-700 ease-in-out ${item?.images?.length > 1 ? 'group-hover:opacity-0': '' }  `}
              />
              {item?.images?.length > 1 &&
                <img
                  src={item?.images[1]}
                  alt={item?.name}
                  className="absolute  top-0 left-0 bg-[#F9F9F9] w-full h-full object-cover opacity-0 transition-all duration-700 ease-in-out group-hover:opacity-100 "
                />
              }

            </div>
          )}
        </div>
      </ScrollReveal>
      <ScrollReveal animationClass="slide-in-top h-full w-full">
        <div className='flex justify-between h-full flex-col gap-1 p-2.5 lg:p-3.5 w-full  rounded-b-lg items-start'>

          <h3 className="font-customBold mb-0.5 text-sm lg:text-base text-ellipsis line-clamp-1  text-theme-headingColor text-start">
            {item?.name}
          </h3>

          <div className='flex flex-col gap-0.5 lg:gap-1 items-start'>

            {/* <p className="text-theme-subHeadingColor font-customRegular text-ellipsis line-clamp-2 text-xs lg:text-sm  text-start"
            dangerouslySetInnerHTML={{ __html: item?.description }}>
              
            </p> */}


            <div className="flex flex-wrap items-center gap-x-1.5 lg:gap-x-2">

              <p className="font-customSemiBold text-sm lg:text-base text-start text-theme-headingColor">
                ₹ {formatPrice(item?.final_price)}
              </p>
              {item?.final_price !== item?.regular_price && item?.regular_price && (
                <p className="text-theme-subHeadingColor font-customMedium text-xs lg:text-sm  line-through">
                  ₹ {formatPrice(item?.regular_price)}
                </p>
              )}
            </div>

          </div>

        </div>
      </ScrollReveal>

    </Link>

  );
};

export default ProductCard;
