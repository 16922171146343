import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import apiService from '../utility-functions/Api-Services';    
import { formatPrice } from '../utility-functions/formatters';

function Search() {
    const [search, setSearch] = useState(false);
    const debounceTimeoutRef = useRef(null); // useRef to store timeout ID

    const toggleSearch = () => {
        setSearch(!search);
        setQ('');   
        if (!search) {
          document.body.style.overflow = 'hidden';
      } else {
          document.body.style.overflow = 'auto';
      }     
        setTimeout(() => {          
          let searchBox=document.getElementById('searchBox');      
          if(searchBox!=null){
            console.log(search,'search');            
            searchBox.focus();
          }
        }, 1000);      
    };   
    //Search Api Integration ......
    const [q,setQ]=useState([])
    const [searchItems, setSearchItems] = useState([]);

    const doSearch=(event)=>{
      const searchInputValue = event.target.value;
      setQ(searchInputValue)
    }
    const clear=()=>{
      let searchBox=document.getElementById('searchBox');      
      searchBox.placeholder='Search...';
      searchBox.value='';
      setQ('');
    }

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      getSearchResults();
    }, 200);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [q]);

    const getSearchResults = () => {
      const getParams = {
        query: q.length>0?q:'',
      };
      apiService.getRequest('/search', getParams).then((response) => {
          // setIsLoading(false);
        if (response.status === 200) {
          // response.data.quantity = 1
          setSearchItems(response.data.products);
        }else {
          // setIsLoading(false);
          console.error(response.body.message);
        }
      })
      .catch((err) => {
        console.error(err.message || 'An error occurred while fetching search results.');
      });
    };    

    

    return (
      <div className="relative">
        <button
          type="button"
          className="relative rounded-full bg-white border border-gray-300 w-8 h-8 flex items-center justify-center"
          onClick={toggleSearch}
        >
          <img src="/assets/icons/search.svg" alt="Search" className="w-3.5" />
        </button>

        {search && (
          <div className="fixed sm:absolute top-12 shadow-[0px_1px_10px_rgba(0,0,0,0.25)] end-[3%] md:end-0 w-[92%] sm:w-[36rem] bg-white rounded-lg transition-opacity z-50">
            <div className="max-h-vh70 flex flex-col overflow-y-auto">
              <div className="flex justify-between items-center gap-2 p-4 border-b border-theme-borderColor">
                <span
                  onClick={toggleSearch}
                  className="min-w-fit cursor-pointer"
                >
                  <img
                    src="/assets/icons/left-arrow.svg"
                    alt=""
                    className="size-4"
                  />
                </span>
                <input
                  type="text"
                  id="searchBox"
                  placeholder="Search..."
                  onChange={doSearch}
                  className="input-box py-0 border-0 w-full"
                />
                <span className="min-w-fit cursor-pointer" onClick={clear}>
                  <span className="text-xs font-customMedium text-red-600 underline">
                    Clear
                  </span>
                </span>
              </div>

              {searchItems.length === 0 && q !="" ? (
                 <div className="flex items-center justify-center min-h-[30vh] py-8 text-center">
                 <div className="max-w-full px-4">
                   <h1 className="text-theme-headingColor text-lg sm:text-md font-customBold break-words overflow-hidden">
                     We have found no result for 
                     <span className="break-words"> {q} </span>
                   </h1>
                   <p
                     onClick={clear}
                     className="text-center text-theme-subHeadingColor text-sm sm:text-base md:text-lg font-customMedium underline cursor-pointer"
                   >
                     Please try again.
                   </p>
                 </div>
               </div>
              ) : (
                <ul className="p-4 flex-1 overflow-y-auto scroll-style grid grid-cols-1 xs:grid-cols-2 gap-6">
                  {searchItems.map((item) => (
                    <li key={item.product_id}>
                      <Link
                        to={`/product-details/${item.product_id}`}
                        className="flex sm:grid grid-cols-7 gap-3 pb-4 border-b border-theme-borderColor"
                        onClick={toggleSearch}
                      >
                        <div className="w-[4rem] aspect-[1/1] min-w-[4rem] xs:w-[3rem] flex justify-center items-center xs:min-w-[3rem] sm:w-full sm:min-w-[unset] sm:col-span-2 h-full bg-[#F9F9F9] rounded-lg">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="aspect-[1/1] w-full object-cover rounded-lg bg-[#F9F9F9]"
                          />
                        </div>
                        <div className="sm:col-span-5 flex flex-col justify-between">
                          <div>
                            <h5 className="text-theme-headingColor text-ellipsis line-clamp-2 text-[13px] font-customSemiBold">
                              {item.name}
                            </h5>
                            {/* <p
                              className="text-theme-subHeadingColor text-ellipsis line-clamp-2 text-[11px] font-customRegular"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p> */}
                          </div>
                          <h5 className="text-theme-headingColor text-[13px] font-customBold">
                            ₹ {formatPrice(item.final_price)}
                          </h5>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        {search && (
          <div
            onClick={toggleSearch}
            className="bg-overly z-[40] block lg:backdrop-blur-none lg:bg-transparent"
          ></div>
        )}
      </div>
    );
}

export default Search