import React from 'react';
import useScrollReveal from '../hooks/useScrollReveal';

function ScrollReveal({ children, animationClass }) {
    const [isVisible, elementRef] = useScrollReveal(0.1);

    return (
        <div
            ref={elementRef}
            className={`${isVisible ? '' : 'before-animation'} ${animationClass}`}>
            {children}
        </div>
    );
}

export default ScrollReveal;
