
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useCustomToast from '../hooks/useCustomToast';

const Footer = () => {
  const { showToast} = useCustomToast();
  const [accordion, setAccordion] = useState(null);
  const toggleAccordion = (index) => {
    setAccordion(accordion === index ? null : index);
  };

  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (!email) {
      showToast('error', "Email is required!");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast('error', "Invalid email address!");
    } else {
      // toast.success("Subscription successful!");
    }
  };

  return (
    <div className="bg-theme-accentColorSecondary w-full px-5 md:px-8 xl:px-20  pb-16 md:pb-0">
      <div  className="flex items-center lg:items-start flex-col gap-5  py-8">
        <span><img src="/assets/icons/adbis-white-logo.svg" alt="adbis-logo" className="w-28" /></span>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-5 lg:gap-4 xl:gap-12 2xl:gap-20 ">
          {/* Logo and Description */}
          <ul className="flex lg:w-4/5 lg:col-span-2 items-center lg:items-start flex-col gap-3 xl:gap-5">

            <li>
              <p className="text-lg md:text-xl lg:text-3xl font-customBold text-center lg:text-start text-white">
                  Maximize your earnings with every share!
              </p>
            </li>
            <li className="flex items-center justify-start gap-3 xl:gap-4">
              <Link  target="_blank" to='https://play.google.com/store/apps/details?id=com.app.adbis&hl=en' rel="noreferrer">
                <img src="/assets/icons/google-play-icon.svg" alt="Facebook" className="w-28 cursor-pointer" />
              </Link>

            </li>
          </ul>

          {/* Menu Accordion */}
          <div className="flex flex-col">
            <div onClick={() => toggleAccordion(1)} className={`flex justify-between items-center gap-2 ${accordion === 1 ? 'mb-3' : 'mb-0 lg:mb-3'}`}>
              <h2 className="text-base font-customSemiBold text-white">Explore</h2>
              <img
                src={accordion === 1 ? '/assets/icons/minus.svg' : '/assets/icons/plus.svg'}
                alt="arrow"
                className={`w-5 block lg:hidden ease-in-out transform-gpu duration-500 ${accordion !== 1 ? 'rotate-180' : ''}`}
              />
            </div>
            <ul className={`flex flex-col items-start gap-3 justify-start overflow-hidden duration-500 ease-in-out ${accordion === 1 ? 'max-h-[160px] opacity-100' : 'max-h-[0] lg:max-h-[unset] opacity-0 lg:h-fit lg:opacity-100'}`}>
              <li><Link to="/listing" className="cursor-pointer text-sm font-customMedium text-white">Products</Link></li>
              <li><Link to="/wallet" className="cursor-pointer text-sm font-customMedium text-white">Wallet</Link></li>
              {/* <li><Link to="/referral" className="cursor-pointer text-sm font-customMedium text-white">Refer & Earn</Link></li> */}
              <li><Link to="/contact-us" className="cursor-pointer text-sm font-customMedium text-white">Contact Us</Link></li>
              <li><Link to="/cms/cancellation-policy" className="cursor-pointer text-sm font-customMedium text-white">Cancellation Policy</Link></li>

            </ul>
          </div>

          {/* Policies Accordion */}
          <div className="flex flex-col">
            <div onClick={() => toggleAccordion(2)} className={`flex justify-between items-center gap-2 ${accordion === 2 ? 'mb-3' : 'mb-0 lg:mb-3'}`}>
              <h2 className="text-base font-customSemiBold text-white">About</h2>
              <img
                src={accordion === 2 ? '/assets/icons/minus.svg' : '/assets/icons/plus.svg'}
                alt="arrow"
                className={`w-5 block lg:hidden ease-in-out transform-all duration-500 ${accordion !== 2 ? 'rotate-180' : ''}`}
              />
            </div>
            <ul className={`flex flex-col items-start gap-3 justify-start overflow-hidden duration-500 ease-in-out ${accordion === 2 ? 'max-h-[160px] opacity-100' : 'max-h-[0] lg:max-h-[unset] opacity-0 lg:h-fit lg:opacity-100'}`}>
              <li><Link to="cms/about-us" className="cursor-pointer text-sm font-customMedium text-white">About Us</Link></li>
              <li><Link to="/cms/privacy-policy" className="cursor-pointer text-sm font-customMedium text-white">Privacy Policy</Link></li>
              <li><Link to="/faq" className="cursor-pointer text-sm font-customMedium text-white">FAQs</Link></li>
              <li><Link to="/cms/terms-of-use" className="cursor-pointer text-sm font-customMedium text-white">Terms</Link></li>

            </ul>
          </div>


          {/* Subscription Section */}
          <div className="flex lg:col-span-2  items-center lg:items-start w-full flex-col gap-3 xl:gap-5">
            <h2 className="text-base font-customSemiBold text-white">Subscribe</h2>
            <div className='flex flex-col gap-2'>
              <p className="text-sm w-3/4 mx-auto lg:mx-[unset] text-center sm:text-center lg:text-start font-customRegular text-white">
                Drop your email below and get the latest drops to your mail.
              </p>
              <div className="relative">
                <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} className="border px-4 pr-24 border-[#E5E5E5] input-box bg-white w-full" />
                <button onClick={handleSubmit} className="absolute top-1/2 py-2 -translate-y-1/2 right-2 capitalize text-xs font-customSemiBold theme-button rounded-md w-[80px] flex items-center justify-center">  
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="p-4 border-t text-[13px] text-white font-customRegular w-full border-[#FFFFFF80] text-center">
        Copyright © 2024 Adbis. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;




