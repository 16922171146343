  import { createSlice } from '@reduxjs/toolkit';

  const initialState = {
    type: 'login', 
  };

  const currentLoginFlowPopupSlice = createSlice({
    name: 'currentLoginFlowPopup',
    initialState,
    reducers: {
      setPopupType: (state, action) => {
        state.type = action.payload;
      },
    },
  });

/*   export const { setPopupType } = currentLoginFlowPopupSlice.actions;
  export default currentLoginFlowPopupSlice.reducer; */
