import React, {useState,useEffect} from 'react';
import { setPopupStatus, setPopupType, setPopupData } from '../redux/slices/popupModal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import apiService from '../utility-functions/Api-Services';
import { setUserData } from '../redux/slices/userDataSlice';
import usePasswordVisibility from '../hooks/usePasswordVisibility';
import useCustomToast from '../hooks/useCustomToast';
import { getCartItems, handleOfflineToOnline } from '../utility-functions/addToCartHelper';
import { setCartData } from '../redux/slices/updateCurrentCartDataSlice';

const Login = () => {
  const { showToast} = useCustomToast();
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberMeStatus, setRememberMeStatus] = useState('false');
  const dispatch = useDispatch();
  function doSetupPopupType(status, type = null, data = null ) {
    dispatch(setPopupStatus(status));
    dispatch(setPopupType(type));
    dispatch(setPopupData(data));
  }

  const currentCartData = useSelector((state) => state.cartData.data, shallowEqual);
  const { register, handleSubmit, formState: { errors, isSubmitted }, reset ,setValue} = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { passwordVisibility, togglePasswordVisibility } =
    usePasswordVisibility(['password']);
  const onSubmit = (data) => {

    const pushwooshToken = localStorage.getItem('pushwooshToken') || ''

    setIsSubmitting(true);
    const url = '/login';
    const getParams = {};
    const postParams = {
      email: data.email,
      password: data.password,
      device_type: "W", 
      device_model: "",
      device_token: pushwooshToken,
      app_version: "",
    };
    apiService.postRequest(url, getParams, postParams)
      .then((response) => {
        if (response.status === 200) {
          showToast('success', response.message);
          const data = response.data;
         /*  dispatch(setUserData(data)); */
          if(data?.is_verified === 0){
            doSetupPopupType(true, 'otpVerification', data);
          }
          else if(data?.is_verified === 1){ 
            dispatch(setUserData(data))
            doSetupPopupType(false);
            doOfflineToOnlineCart(data)
          }
          setIsSubmitting(false);
        } else {
          showToast('error', response.message);
          reset();
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        showToast('error', err.message);
        setIsSubmitting(false);
      });
  };

  const doOfflineToOnlineCart = (userData) => {
    getCartItems(userData, showToast).then((res) => {                
      if (res) {
        const { items } = res;
        
        if ((items && items.length === 0) && (currentCartData && currentCartData.length > 0)) {
          handleOfflineToOnline(currentCartData, userData, showToast)
            .then((res) => {
              if (res.items.length > 0) {
                const cartData = res;
                dispatch(setCartData(cartData.items));
              }
            })
            .catch((err) => {
              console.error("Error in addToCartMain:", err);
            });
        }

        if ((items && items.length > 0) && (currentCartData && currentCartData.length > 0)) {
          let updatedCartData = [];
          updatedCartData = [...currentCartData, ...items];
          handleOfflineToOnline(updatedCartData, userData, showToast)
            .then((res) => {
              if (res.items.length > 0) {
                const cartData = res;
                dispatch(setCartData(cartData.items));
              }
            })
            .catch((err) => {
              console.error("Error in addToCartMain:", err);
            });
        }
        
        if ((items && items.length > 0) && (currentCartData && currentCartData.length === 0)) {
              dispatch(setCartData(items));
        }
      }
    })
    .catch((err) => {
      console.error('Error in addToCartMain:', err);
    });
  }
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  
  useEffect(() => {
    const savedData = JSON.parse(sessionStorage.getItem('loginFormData') || '{}');
    const currentPopupType = sessionStorage.getItem('currentPopupType') || '';
    if (savedData.email && savedData.password && currentPopupType !== 'terms') {
      setValue("email", savedData.email);
      setValue("password", savedData.password);
      setFormData(savedData);
    }  
    if (currentPopupType === 'forgotPassword' || currentPopupType === 'register') {
      sessionStorage.removeItem('loginFormData');
      reset();
      setFormData({ email: '', password: '' });
    }  
    return () => {
      if (currentPopupType === 'forgotPassword' || currentPopupType === 'register') {
        sessionStorage.removeItem('loginFormData');
        reset();
        setFormData({ email: '', password: '' });
      }
    };
  }, [setValue, reset]);
    
  useEffect(() => {
    const savedStatus = sessionStorage.getItem('rememberMeStatus'); 
    const savedFormData = sessionStorage.getItem('loginFormData');
    if (savedStatus === 'true') {
      setRememberMe(true);
    }
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
    sessionStorage.setItem('loginFormData', JSON.stringify(updatedData));

    if (rememberMe) {
      sessionStorage.setItem('loginFormData', JSON.stringify(updatedData));
    }
  };

  //remember me status
  const handleRememberMeChange = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    const newStatus = isChecked ? 'true' : 'false';
    sessionStorage.setItem('rememberMeStatus', newStatus);
    if (isChecked) {
      sessionStorage.setItem('loginFormData', JSON.stringify(formData));
    } else {
      sessionStorage.removeItem('loginFormData');
    }
  };
    
  const errorMsgClass = 'text-red-600 text-xs ps-1 font-customRegular';
  const images = [
    {
      id: 1,
      image: "/assets/images/login-banner.svg",
    },
    {
      id: 2,
      image: "/assets/images/login-banner-2.svg",
    }
  ]
  return (
    <div  className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] items-center lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
      <div className="hidden lg:block w-full max-h-[calc(90vh-40px)] min-h-[75vh] h-full bg-cover rounded-xl">
        <Swiper
          {...loginSliderConfig}
          modules={[Navigation, Pagination, Controller, Autoplay]}
         className='h-full'>
          {images.map((item) => (
            <SwiperSlide key={item.id}>
               <img src={item.image} alt="" className='w-full h-full object-cover rounded-xl' />
            </SwiperSlide>
          ))}
        </Swiper>
        
      </div>

      <div className="w-full  mb-16 lg:px-12 max-h-[calc(90vh-104px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8">
        <div className="text-center">
          <p className="text-2xl md:text-3xl text-theme-headingColor pb-1 font-customBold">
            Sign in to your Account
          </p>
          <p className='text-theme-subHeadingColor text-sm font-customMedium'>
            Don't have an account?{" "}
            <span
              onClick={() => doSetupPopupType(true, "register")}
              className='font-customBold capitalize text-theme-accentColorSecondary underline cursor-pointer'
            >
              Sign up
            </span>
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 overflow-y-auto scroll-style flex-col gap-8'>
          <div className='flex flex-col gap-4 flex-1 overflow-y-auto scroll-style '>
            <div >
              <input
                {...register("email", {
                  required: "Please enter your email address.",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                })}
                id="email"
                type="email"
                placeholder="Email"
                className='input-box'
                aria-label="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <small className={errorMsgClass}>{errors.email.message}</small>
              )}
            </div>

            <div >
              <div className='relative'>
              <input
               type={passwordVisibility.password.isVisible ? 'text' : 'password'}
                {...register("password", {
                  required: "Please enter your password.",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
                id="password"
                placeholder="Password"
                className='input-box'
                aria-label="Password"
                value={formData.password}
                onChange={handleChange}
              />
              <img
                  src={passwordVisibility.password.icon}
                  alt="password"
                  onClick={() => togglePasswordVisibility('password')}
                  className="password-icon"
                />
              </div>
              {errors.password && (
                <small className={errorMsgClass}>{errors.password.message}</small>
              )}
              <div className='mt-2 flex justify-between items-center'>
                <label htmlFor='remember' className='flex items-center gap-2'>
                  <input name='remember' id='remember' type="checkbox" className='size-[16px] accent-theme-accentColorSecondary'checked={rememberMe}
            onChange={handleRememberMeChange} />
                  <p className='text-[13px] text-theme-subHeadingColor font-customMedium block text-end cursor-pointer'>Remember me</p>
                </label>
                <p
                  onClick={() => doSetupPopupType(true, 'forgotPassword')}
                  className="text-[13px] text-theme-accentColorSecondary font-customSemiBold block text-end cursor-pointer"
                >
                  Forgot Password?
                </p>
              </div>
             
            </div>
          </div>
          {/*  onClick={() => doSetupPopupType('otpVerification')} */}

          
          <button
            type="submit"
            disabled={isSubmitting}
            className="theme-button py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary"
          >
            <span className={`button-text duration-300 ease-in-out`}>
              {isSubmitting ? 'Loading...' : 'Login'}
            </span>
          </button>
        </form>

       
      </div>

      <div className='text-center w-full absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2 '>
          <p className='text-[13px] font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor'>By signing up, you agree to the <span className='text-theme-accentColorSecondary font-customBold'><Link onClick={() => doSetupPopupType(true , 'term')}>Terms of Use</Link></span> and <span className='text-theme-accentColorSecondary font-semibold'><Link onClick={() => doSetupPopupType(true,'dataDeletion')}>Data Deletion Policy</Link></span></p>
      </div>
    </div>
  );
};

export default Login;
