import axios from 'axios';

class ApiServices {
  constructor() {

    const hostName = window.location.hostname;
    const baseUrl = hostName.includes('localhost') || hostName.includes('web.ad-bis.com')
    ? process.env.REACT_APP_API_URL_DEV // Dev API
    : process.env.REACT_APP_API_URL_ADMIN; // Admin API

    this.apiClient = axios.create({
      baseURL: baseUrl, // Base URL
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // GET Request
  getRequest = async (url, getParams = {}, headers = {}) => {
    try {
      const response = await this.apiClient.get(url, {
        params: getParams,
        // headers: { ...this.apiClient.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      console.error('GET Request Error:', error);
      throw error;
    }
  };

  // POST Request
  postRequest = async (url, getParams = {}, postParams = {}, headers = {}) => {
    try {
      const response = await this.apiClient.post(url, postParams, {
        params: getParams,
        headers: { ...this.apiClient.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      console.error('POST Request Error:', error);
      throw error;
    }
  };

  postRequestFormData = async (url, getParams = {}, postParams = {}, headers = {}) => {
    try {
      const response = await this.apiClient.post(url, postParams, {
        params: getParams,
        headers: { ...this.apiClient.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      console.error('POST Request Error:', error);
      throw error;
    }
  };

  // PUT Request
  putRequest = async (url, getParams = {}, postParams = {}, headers = {}) => {
    try {
      const response = await this.apiClient.put(url, postParams, {
        params: getParams,
        headers: { ...this.apiClient.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      console.error('PUT Request Error:', error);
      throw error;
    }
  };

  // DELETE Request
  deleteRequest = async (url, getParams = {}, headers = {}) => {
    try {
      const response = await this.apiClient.delete(url, {
        params: getParams,
        headers: { ...this.apiClient.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      console.error('DELETE Request Error:', error);
      throw error;
    }
  };
}

const apiService = new ApiServices();
export default apiService;
