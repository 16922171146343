import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import {  useSelector, shallowEqual } from 'react-redux';
import CategoryCard from '../components/CategoryCard';
import ProductCard from '../components/Product-card';

// Lazy load components
const Home = lazy(() => import('../pages/Home'));
const Listing = lazy(() => import('../pages/purchase/Listing'));
const ProductDetails = lazy(() => import('../pages/purchase/ProductDetails'));
const Checkout = lazy(() => import('../pages/purchase/Checkout'));
const OrderConfirmation = lazy(() => import('../pages/purchase/OrderConfirmation'));
const Cms = lazy(() => import('../pages/cms/Cms'));
const Faq = lazy(() => import('../pages/cms/Faq'));
const ContactUs = lazy(() => import('../pages/cms/Contact-Us'));
const MyAccount = lazy(() => import('../pages/my-account/MyAccount'));
const Referral = lazy(() => import('../pages/ReferAndEarn'));
const Wallet = lazy(() => import('../pages/wallet/Wallet'));

const AppRouter = () => {
  const userData = useSelector((state) => state?.userData?.data, shallowEqual);
  const location = useLocation();
  const shouldHideHeaderFooter = ['/checkout', '/order-confirmation'].some((path) =>
    location.pathname.includes(path)
  );

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    const pathName=window.location.pathname;    
    if(!pathName.includes('/product-details/')){
      sessionStorage.removeItem('prodRefCode')
    }
  });

  return (
    // for temp added this loader
    <Suspense fallback={<div className='fixed inset-0 backdrop-blur-sm z-[9999] bg-[#0000004D] loader'>
      <img src="/assets/icons/adbis-logo.svg" alt="adbis-logo" className='fixed size-28 inset-1/2 -translate-x-1/2 -translate-y-1/2' />
      <div className="loader-line-mask">
        <div className="loader-line"></div>
      </div>
    </div>}>

      <div className='min-h-screen w-full flex flex-col items-stretch'>
        {!shouldHideHeaderFooter && <Header />}
        <div className="flex-grow min-h-[90vh]">
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/listing" element={<Listing />} />
            <Route path="/listing/:id" element={<Listing />} />
            <Route path="/product-details/:id" element={<ProductDetails />} />
            <Route path="/checkout"  element={userData?.id ? <Checkout /> : <Navigate to="/" replace />}/>
            <Route path="/order-confirmation" element={userData?.id ? <OrderConfirmation /> : <Navigate to="/" replace />} />
            <Route path="/cms" element={<Cms />} />
            <Route path="/cms/:type" element={<Cms />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/my-account/*"  element={
  <Suspense>
    {userData?.id ? <MyAccount /> : <Navigate to="/" replace />}
  </Suspense>
} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        {!shouldHideHeaderFooter && <Footer />}
      </div>
    </Suspense>
  );
};

export default AppRouter;
