import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: 'addAddress', 
};

const addressFlowPopupSlice = createSlice({
  name: 'addressFlowPopup',
  initialState,
  reducers: {
    setPopupType: (state, action) => {
      state.type = action.payload;
    },
  },
});

/* export const { setPopupType } = addressFlowPopupSlice.actions;
export default addressFlowPopupSlice.reducer; */
