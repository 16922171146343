import { useRef, useEffect, useState } from 'react';

function useScrollReveal(threshold = 0.1, top = 0) {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        if (!observerRef.current) {
            observerRef.current = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observerRef.current.unobserve(elementRef.current);
                    }
                },
                { threshold }
            );
        }
        if (elementRef.current) {
            observerRef.current.observe(elementRef.current);
        }

        return () => {
            observerRef.current.disconnect();
            observerRef.current = null;
        };
    }, [threshold, top]);

    return [isVisible, elementRef];
}

export default useScrollReveal;
