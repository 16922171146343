import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { setPopupStatus, setPopupType, setPopupData } from '../redux/slices/popupModal';
import { useDispatch } from 'react-redux';
import { Controller as RHFController, useForm } from "react-hook-form";
import { loginSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Select from "react-select";
import apiService from '../utility-functions/Api-Services';
import usePasswordVisibility from '../hooks/usePasswordVisibility';
import { fetchStateData, fetchDistrictData, fetchTalukaData } from '../redux/slices/addressHelper';
import { setUserData } from '../redux/slices/userDataSlice';
import useCustomToast from '../hooks/useCustomToast';

const Register = ({referCode}) => {
  const { showToast} = useCustomToast();
  const { control, register, watch, handleSubmit, formState: { errors }, reset,setValue } = useForm();
  const dispatch = useDispatch();
  const { passwordVisibility, togglePasswordVisibility } =
    usePasswordVisibility(['newPassword', 'confirmPassword']);

    const newPassword = watch("newPassword");
    const confirmPassword = watch("confirmPassword");
  
    // Conditional validation for "Change Password" fields
    const shouldValidatePasswordFields =
     newPassword || confirmPassword;

  function doSetupPopupType(status, type = null , data = null) {
    dispatch(setPopupType(type));
    dispatch(setPopupStatus(status));
    dispatch(setPopupData(data));
  }

  const handleNumericInput = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      showToast('error', 'Passwords do not match!');
      return;
    }
    
    const pushwooshToken = localStorage.getItem('pushwooshToken') || ''

    setIsSubmitting(true);
      const url = '/register';
      const getParams = {};
      const postParams = {
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        phone_code: selectedCountryCode?.code.replace('+', '') || '',
        phone: data?.phone,
        password: data?.password,
        device_type: "W",
        state_id: data.state_id || '',
        district_id: data.district_id || '',
        taluka_id: data.taluka_id || '',
        // state_id: selectedState?.value || '',
        // district_id: selectedDistrict?.value || '',
        // taluka_id: selectedTaluka?.value || '',
        referral_code: data?.referralCode || "",
        device_model: "",
        device_token: pushwooshToken,
        app_version: "",
        os_version: window.navigator.platform,

      };
      apiService.postRequest(url, getParams, postParams)
        .then((response) => {
          if (response.status === 200) {
            showToast('success', response.message);
            const data = response.data;
           
            reset();
            if(data?.is_verified === 0){
              doSetupPopupType(true,'otpVerification', data);
            }
            // no need for this just for temp purposes
            else{ 
              dispatch(setUserData(data));
            }
            setIsSubmitting(false);
          } else {
            showToast('error', response.message);
            setIsSubmitting(false);
          }
        })
        .catch((err) => {
          showToast('error', err.message);
          setIsSubmitting(false);
        });
  };

  const [formData, setFormData] = useState({
    firstName:'',
    email: '',
    lastName:'',
    phone:'',
    password:'',
    confirmPassword:'',
    referralCode:'',
    selectedState: '',
  });


  useEffect(() => {
    const savedData = JSON.parse(sessionStorage.getItem('regFormData') || '{}');
    const currentPopupType = sessionStorage.getItem('currentPopupType') || '';

    if ( currentPopupType !== 'terms') {
      setValue("firstName", savedData.firstName);
      setValue("lastName",savedData.lastName)
      setValue("email", savedData.firstName);
      setValue("phone",savedData.phone);
      setValue("password",savedData.password);
      setValue("confirmPassword",savedData.confirmPassword);
      setValue("referralCode",savedData.referralCode)
      setValue("selectedState", savedData.selectedState);
      setFormData(savedData);
    }

    if (currentPopupType == 'login') {
      sessionStorage.removeItem('regFormData');
      reset();
      setFormData({ email: '',firstName:'',lastName:'',phone:'' ,password:'',confirmPassword:'',referralCode:'',selectedState:''});
    }

    return () => {
      if (currentPopupType == 'login') {
        sessionStorage.removeItem('regFormData');
        reset();
        setFormData({ email: '',firstName:'',lastName:'',phone:'' ,password:'',confirmPassword:'',referralCode:'',selectedState:''});
      }
    };
  }, [setValue, reset]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);

    sessionStorage.setItem('regFormData', JSON.stringify(updatedData));
  };
  const handlePopupClose = () => {
    const currentPopupType = localStorage.getItem('currentPopupType') || '';
    if (currentPopupType == 'login') {
      sessionStorage.removeItem('regFormData');
      reset(); 
      setFormData({firstName:'',lastName:'',phone:'',email: '',password:'',confirmPassword:'',referralCode:'' });
    }
    dispatch(setPopupStatus(false));
  };


  const errorMsgClass = 'text-red-600 text-xs ps-1 font-customRegular';



  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [talukaData, setTalukaData] = useState([]);  
  useEffect(() => {
    fetchStateData().then((data) => setStateData(data));
     /* const savedState = JSON.parse(localStorage.getItem('selectedState')) || null;
  if (savedState) {
    setSelectedState(savedState);
  } */
  }, []);
  useEffect(()=>{
    setValue("referralCode",referCode);
  },[referCode!=''])

  const [selectedState, setSelectedState] = useState(null)
   const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    //localStorage.setItem('selectedState', JSON.stringify(selectedOption));
    setValue("district_id", null);
    setSelectedDistrict(null); 
    setSelectedTaluka(null); 
    fetchDistrictData(selectedOption.value).then((data) => setDistrictData(data));
   
  };
  
  const [selectedDistrict, setSelectedDistrict] = useState(null)
 const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption);
    setValue("taluka_id", null);
    setSelectedTaluka(null); 
    fetchTalukaData(selectedOption.value).then((data) => setTalukaData(data));
   
  };
   const [selectedTaluka, setSelectedTaluka] = useState(null)
    const handleTalukaChange = (selectedOption) => {
    setSelectedTaluka(selectedOption);
  };
  



  const images = [
    {
      id: 1,
      image: "/assets/images/login-banner.svg",
    },
    {
      id: 2,
      image: "/assets/images/login-banner-2.svg",
    }
  ]

  const countryCodes = [
    { code: "+91", flag: "/assets/icons/ind-flag-img.svg", country: "India" },
    // { code: "+1", flag: "/assets/icons/us-flag-img.svg", country: "USA" },
  ];

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[0]
  );
  const handleCountryChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption);

  };
  const formatOptionLabel = ({ code, flag, country }) => (
    <div className="flex items-center gap-1">
      <img src={flag} alt={country} className="size-4" />
      <span className='text-sm md:text-[15px]'>{code}</span>
    </div>
  );


  const mainDivRef = useRef(null);

  const handleMenuOpen = () => {
    if (mainDivRef.current) {
      mainDivRef.current.classList.add("no-scroll");
    }
  };

  const handleMenuClose = () => {
    if (mainDivRef.current) {
      mainDivRef.current.classList.remove("no-scroll");
    }
  };

  return (
    <div className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
      <div className="hidden lg:block max-h-[calc(90vh-40px)] w-full min-h-[75vh] h-full bg-cover rounded-xl">
        <Swiper
          {...loginSliderConfig}
          modules={[Navigation, Pagination, Controller, Autoplay]}
          className="h-full"
        >
          {images.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={item.image} alt="" className="w-full h-full object-cover rounded-xl" />
            </SwiperSlide>
          ))}
        </Swiper>

      </div>

      <div className="w-full mb-16 lg:px-12 max-h-[calc(90vh-120px)] lg:max-h-[calc(90vh-104px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8">
        <div className=' text-center'>
          <p className="text-2xl md:text-3xl text-theme-headingColor pb-1 font-customBold">Create an Account</p>
          <p className='text-theme-subHeadingColor text-sm font-customMedium'>Already have an account? <span onClick={() => doSetupPopupType(true, 'login')} className='font-customBold text-theme-accentColorSecondary underline cursor-pointer'>Log In</span></p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 overflow-y-auto scroll-style flex-col gap-8'>
          <div ref={mainDivRef} className='flex flex-col gap-4 flex-1 overflow-y-auto scroll-style '>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  placeholder='First name'
                  {...register('firstName', {
                    required: "Please enter your first name"
                  })}
                  className='input-box'
                  value={formData.firstName}
                 onChange={handleChange}
                />
                {errors.firstName && (
                  <small className={errorMsgClass}>{errors.firstName.message}</small>
                )}
              </div>

              <div>
                <input
                  type="text"
                  placeholder='Last name'
                  {...register('lastName', {
                    required: "Please enter your last name"
                  })}
                  className='input-box'
                  value={formData.lastName}
                 onChange={handleChange}
                />
                {errors.lastName && (
                  <small className={errorMsgClass}>{errors.lastName.message}</small>
                )}
              </div>
            </div>

            <div>
              <input
                type="email"
                inputMode='email'
                placeholder='Email'
                {...register('email', {
                  required: "Please enter your email address",
                })}
                className='input-box'
                value={formData.email}
                 onChange={handleChange}
              />
              {errors.email && (
                <small className={errorMsgClass}>{errors.email.message}</small>
              )}
            </div>

            <div>

              <div className='input-box col-span-2 lg:col-span-1 p-0 items-center flex'>
              <Select
                    isDisabled={true}
                    value={selectedCountryCode}
                    onChange={handleCountryChange}
                    options={countryCodes}
                    formatOptionLabel={formatOptionLabel}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: "none",
                        width: "100%",
                        opacity: 1,
                        backgroundColor: state.isDisabled ? "white" : provided.backgroundColor, 
                      }),
                      indicatorsContainer: (provided, state) => ({
                        ...provided,
                        display: state.isDisabled ? "none" : provided.display
                      })
                    }}
                    className="country-select disable-select text-theme-headingColor cursor-not-allowed px-2 pe-4 w-full max-w-[75px] text-sm outline-none !border-none"
                  />
                  <div className="h-[22px] w-[2px] rounded bg-[#dcdcdc]"></div>
                <input type="tel" placeholder="Phone Number" onInput={handleNumericInput} maxLength={10}
                  {...register("phone", {
                    required: "Phone number is required",
                    minLength: { value: 10, message: "Phone number must be at least 10 digits" },
                    maxLength: { value: 10, message: "Phone number must be at most 10 digits" }
                  })} className="input-box rounded-s-none px-3 border-0 focus:outline-none" 
                  value={formData.phone}
                 onChange={handleChange}/>
              </div>
              {errors.phone && (
                <small className={errorMsgClass}>{errors.phone.message}</small>
              )}
            </div>

            <div>
              <div className='relative'>
                <input
                  type={passwordVisibility.newPassword.isVisible ? 'text' : 'password'}
                  placeholder='New password'
                  {...register('password', {
                    required: "Please set a password before proceeding further",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    }
                  })}
                  className='input-box'
                  value={formData.password}
                 onChange={handleChange}
                />
                <img
                  src={passwordVisibility.newPassword.icon}
                  alt="password"
                  onClick={() => togglePasswordVisibility('newPassword')}
                  className="password-icon"
                />
              </div>
              {errors.password && (
                <small className={errorMsgClass}>{errors.password.message}</small>
              )}
            </div>

          <div>
          <div className='relative'>
        <input
          type={passwordVisibility.confirmPassword.isVisible ? 'text' : 'password'}
          placeholder='Confirm Password'
          {...register('confirmPassword', {
            required: "Please confirm your password",
          })}
          className='input-box'
          value={formData.confirmPassword}
                 onChange={handleChange}
        />
        <img
          src={passwordVisibility.confirmPassword.icon}
          alt="toggle visibility"
          onClick={() => togglePasswordVisibility('confirmPassword')}
          className="password-icon"
        /> </div>
        {errors.confirmPassword && (
          <small className={errorMsgClass}>{errors.confirmPassword.message}</small>
        )}
        {watch('confirmPassword') && watch('password') !== watch('confirmPassword') && (
          <small className={errorMsgClass}>Passwords do not match</small>
        )}
      </div>

            <div>
                <input
                  type="text"
                  placeholder='Referral Code'
                  {...register('referralCode')}
                  className='input-box'
                  value={formData.referralCode}
                 onChange={handleChange}
                /> 
              </div>

            <div className={`grid gap-4 ${selectedState?.districtLength > 0 ? 'md:grid-cols-2' : 'grid-cols-1'}`}>

            
            <div>
            <RHFController control={control} name="state_id"  render={({ field: { onChange } }) => (
            <>
              <Select menuPlacement="top" 
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                value={selectedState} onChange={(selectedOption) => {
                onChange(selectedOption.value);
                handleStateChange(selectedOption);
              }}
                placeholder="Select State"
                options={stateData?.map((state) => ({
                  value: state.state_id, label: state.name, districtLength:
                    state?.has_districts ?? 0
                }))}
                className="input-box px-0 py-0 border-0 custom-select"
              />
            </>
          )}
          />
            </div>
            
            {selectedState?.districtLength > 0 && (
               <div>
                <RHFController
            control={control}
            name="district_id"
            rules={{ required: "District is required" }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <Select
                  menuPlacement="top"
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  placeholder="Select a District"
                  value={selectedDistrict}
                  onChange={(selectedOption) => {
                    onChange(selectedOption.value); // Pass value to the hook form
                    handleDistrictChange(selectedOption); // Update state and fetch Taluka data
                  }}
                  options={districtData?.map((district) => ({
                    value: district.district_id,
                    label: district.name,
                    talukaLength: district?.has_talukas ?? 0,
                  }))}
                  className="input-box px-0 py-0 border-0 custom-select"
                />
                {error && <p className="text-red-600 text-xs mt-1">{error.message}</p>}
              </>
            )}
          />
             </div>
            )}
           
           </div>
           {selectedState?.districtLength > 0 && selectedDistrict?.talukaLength > 0  && (
            <div>
             <RHFController
            control={control}
            name="taluka_id"
            rules={{ required: "Taluka is required" }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <Select
                  menuPlacement="top"
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  placeholder="Select a Taluka"
                  value={selectedTaluka}
                  onChange={(selectedOption) => {
                    onChange(selectedOption.value); // Pass value to the hook form
                    handleTalukaChange(selectedOption); // Update state or perform any additional logic
                  }}
                  options={talukaData?.map((taluka) => ({
                    value: taluka.taluka_id,
                    label: taluka.name,
                  }))}
                  className="input-box px-0 py-0 border-0 custom-select"
                />
                {error && <p className="text-red-600 text-xs mt-1">{error.message}</p>}
              </>
            )}
          />
            </div>
           )}


          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="theme-button py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary">
            <span className={`button-text duration-300 ease-in-out`}>
              {isSubmitting ? 'Loading...' : 'Next'}
            </span>
          </button>

        </form>


      </div>

      <div className='text-center w-full absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2 '>
        <p className='text-[13px] font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor'>By signing up, you agree to the <span className='text-theme-accentColorSecondary font-customBold'><Link  onClick={() => doSetupPopupType(true , 'term')}>Terms of Use</Link></span> and <span className='text-theme-accentColorSecondary font-semibold'><Link onClick={() => doSetupPopupType(true,'dataDeletion')}>Data Deletion Policy</Link></span></p>
      </div>
    </div>
  );
};

export default Register;
