import { useState } from 'react';

const usePasswordVisibility = (fields) => {
  // Initialize state dynamically based on the fields provided
  const initialState = fields.reduce((acc, field) => {
    acc[field] = { 
        isVisible : false, 
        icon: '/assets/icons/eye-slash.svg' 
    };  
    return acc;
  }, {});

  const [passwordVisibility, setPasswordVisibility] = useState(initialState);

  // Toggle visibility for a specific field
  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: {
        isVisible: !prevState[field].isVisible,
        icon: !prevState[field].isVisible ? '/assets/icons/eye.svg' : '/assets/icons/eye-slash.svg'
      }
    }));
  };

  return {
    passwordVisibility,
    togglePasswordVisibility
  };
};

export default usePasswordVisibility;
